export default {
    type: 'SimpleGraph',
    location: '/business/fuo/volumes/fulfilled',
    filter_keys: [
        ['Filter', 'fuo-vol-3']
    ],
    graph_settings: {
        colors: ['#fddb2a']
    }
}
